import { toast } from "react-toastify";
import store from "../store";
import { userLogin, userProfile } from "../actions";
import { USER, USER_PROFILE } from "../constants/local-storage";

export const sessionExpired = () => {
	toast.error("Session Expired please login again.");
	localStorage.removeItem(USER);
	localStorage.removeItem(USER_PROFILE);
	store.dispatch(userLogin());
	store.dispatch(userProfile());
};
