import { refreshTokenApi } from "../api/auth/refresh-token";

const refreshTokenPromise = (refresh_token) => {
	return new Promise((resolve, reject) => {
		refreshTokenApi({ refresh_token }, (data) => {
			resolve(data);
		});
	});
};

export const checkTokenRefresh = (statusCode, url) => {
	return new Promise(async (resolve, reject) => {
		console.log(statusCode === 401, localStorage.getItem("refresh_token"), !(url || "").includes("/logout"));
		if (statusCode === 401 && localStorage.getItem("refresh_token") && !(url || "").includes("/logout")) {
			let refresh_token = localStorage.getItem("refresh_token");
			localStorage.removeItem("refresh_token");
			try {
				await refreshTokenPromise(refresh_token);
				localStorage.removeItem("refresh_token");
				resolve("ok");
			} catch (error) {
				reject(error);
			}
		} else {
			resolve("ok");
		}
	});
};

export const getHttpStatus = (statusCode) => {
	if (localStorage.getItem("refresh_token") && statusCode === 401) {
		return false;
	}
	return true;
};
