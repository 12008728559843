import { POST } from "../api";
import { REFRESH_TOKEN } from "../../constants/api";
import { asyncLocalStorage } from "../../utils";
import { USER } from "../../constants/local-storage";
import { userLogin } from "../../actions";
import store from "../../store";
import { sessionExpired } from "../../helper/sessionExpired";
import { toast } from "react-toastify";

export const refreshTokenApi = (data, callback) => {
	POST(REFRESH_TOKEN, data, function (data) {
		if (data.status && data.status) {
			asyncLocalStorage.setItem(USER, JSON.stringify(data.data)).then(() => {
				store.dispatch(userLogin());
			});
			toast.success("Your session has been extended. Try Again");
			callback(true);
		} else {
			if (!data.http_error) {
				sessionExpired();
				callback(false);
			}
		}
	});
};
